@font-face {
  font-family: "Angie";
  src: url("../assets/fonts/Angie Small Caps.ttf");
  font-weight: normal;
  font-style: normal;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  color: #003865;
}

body {
  background-color: beige;
}

h1, h2 {
  font-weight: normal;
}

#background-image, #background-color {
  position: fixed;
  top: -200px;
  left: -200px;
  width: calc(100vw + 400px);
  height: calc(100vh + 400px);
}

#background-image {
  background-image: url("../assets/images/parchment.jpg");
  background-repeat: repeat;
  opacity: 0.125;
  z-index: -10;
}

#background-color {
  z-index: -5;
  background: linear-gradient(180deg, rgba(209, 188, 138, 0.25) 0%, rgba(255, 255, 255, 0.125) 100%);
}

#base {
  grid-template-columns: 1fr 3fr 1fr;
  min-height: 100vh;
  padding-top: 2rem;
}
#base #left-spacer, #base #right-spacer {
  min-width: 256px;
}
#base #center-root-container {
  width: 100%;
  padding: 0 1rem;
}
#base #center-root-container #center-root {
  width: 100%;
  margin: 0 auto;
}

.overlay {
  padding-top: 1rem;
  min-width: 256px;
  max-width: 512px;
  z-index: 10;
}

#ham {
  position: fixed;
  width: 2.5rem;
  height: 2.5rem;
  bottom: 2.5rem;
  left: 2.5rem;
  display: none;
  cursor: pointer;
  z-index: 100;
}

#under-ham {
  display: none;
}

@media screen and (max-width: 1024px) {
  #base {
    grid-template-columns: 1fr 3fr;
  }
  #right-overlay, #right-spacer {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  #base {
    grid-template-columns: 1fr;
  }
  #left-spacer {
    display: none;
  }
  #left-overlay {
    background-color: beige;
    transform: translateX(-100%);
    transition: transform 0.25s ease-in-out;
  }
  #left-overlay.open {
    transform: translateX(0);
  }
  #left-overlay::before {
    content: "";
    background-image: url("../assets/images/parchment.jpg");
    background-repeat: repeat;
    opacity: 0.125;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;
  }
  #ham {
    display: block;
    transition: all 0.25s ease-in-out;
  }
  #ham.open {
    transform: rotate(90deg);
    left: calc(100vw - 2.5rem - 2.5rem);
    filter: drop-shadow(0 0 1rem ivory);
  }
  #under-ham {
    display: block;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 0.25s ease-in-out;
    pointer-events: none;
  }
  #under-ham.open {
    opacity: 1;
    pointer-events: all;
  }
  #center-root-container {
    padding: 0;
    max-width: 100vw;
  }
}

