@import "common.scss";

.entry {
  width: 100%;
  max-width: $line;
  margin: 0 auto 8rem;
  padding-bottom: 2rem;
  box-shadow: 0 4rem 2rem -2rem rgba(0 0 0 / .1);

  .entry-header {
    width: 100%;

    h1 {
      font-size: 2.5rem;
      line-height: 2.5rem;
      font-weight: lighter;
    }

    .entry-close {
      width: 2.5rem;
      height: 2.5rem;

      &, * { transition: all .125s ease-in-out; }

      &:hover {
        line { stroke: ivory; }
        filter: drop-shadow(0 0 4px rgba(0 0 0 / .5));
      }
    }

  }

  hr:not(.entry-content hr) {
    margin: 1rem 0;
    border: 1px solid rgba(0 0 0 / .1);
    box-shadow: 0 .25rem .5rem rgba(0 0 0 / .25);
  }

  .entry-content {
    overflow: auto;

    h1, h2, h3, h4, h5, h6 {
      font-weight: normal;
    }

    h1 {
      font-size: 1.75rem;
      line-height: 2rem;
      margin-bottom: .75rem;
      margin-top: 1.75rem;
    }

    h2 {
      font-size: 1.5rem;
      line-height: 1.75rem;
      margin-bottom: .5rem;
      margin-top: 1.5rem;
    }

    h3 {
      font-size: 1.25rem;
      line-height: 1.5rem;
      margin-bottom: .25rem;
      margin-top: 1.25rem;
    }

    p {
      margin-bottom: 1rem;
      word-wrap: break-word;
    }

    hr {
      margin: .5rem 1rem;
      border: 1px solid rgba(0 0 0 / .1);
    }

    ul {
      list-style-type: disc;
      margin-bottom: 1rem;

      li {
        margin-left: 1rem;
      }
    }

    table {
      margin: 0 auto 1rem;
      border-collapse: collapse;

      th, td {
        padding: .25rem;
        border: 1px solid rgba(0 0 0 / .1);
      }
    }

    img {
      max-width: 100%;
      margin: .5rem;
      &:hover { cursor: zoom-in; }
    }

    a {
      text-decoration: none;
      color: $brown;

      &:hover { text-decoration: underline; }
    }

    .float-right { float: right; }
    .br4 img { border-radius: 16px; }

    div { margin: 0 2rem 1rem; }
  }
}