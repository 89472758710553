@import "common.scss";

#right-root {

  #right-overlay-top {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding-right: 1rem;

    span {
      line-height: 2rem;
      font-family: Consolas, monospace, sans-serif;
      color: rgba(0 0 0 / .125);

      @media screen and (max-width: 1500px) {
        display: none;
      }
    }


    button {
      font-family: $base-font;
      font-weight: bold;
      color: $tardis;
      height: 2rem;
      margin-bottom: .5rem;
      padding: 0 1rem;
      border: none;
      box-shadow: .25rem .25rem .5rem rgba(0 0 0 / .25), -.25rem -.25rem .5rem rgba(255 255 255 / .25);
      border-radius: 1rem;
      background-color: rgba(0 0 0 / .1);

      font-size: 1rem;
      line-height: 1.5rem;
      text-align: center;
      text-transform: capitalize;
      transition: all .25s ease;

      &:hover {
        cursor: pointer;
        background-color: rgba(0 0 0 / .25);
        color: ivory;
      }
    }
  }

  hr {
    margin: 1rem 0;
    border: 1px solid rgba(0 0 0 / .1);
  }

  ol {
    counter-reset: item;
  }

  li {
    margin-left: 1rem;
    display: block;
  }

  li:before {
    content: counters(item, ".") " ";
    counter-increment: item;
  }
}