.entry {
  width: 100%;
  max-width: 75ch;
  margin: 0 auto 8rem;
  padding-bottom: 2rem;
  box-shadow: 0 4rem 2rem -2rem rgba(0, 0, 0, 0.1);
}
.entry .entry-header {
  width: 100%;
}
.entry .entry-header h1 {
  font-size: 2.5rem;
  line-height: 2.5rem;
  font-weight: lighter;
}
.entry .entry-header .entry-close {
  width: 2.5rem;
  height: 2.5rem;
}
.entry .entry-header .entry-close, .entry .entry-header .entry-close * {
  transition: all 0.125s ease-in-out;
}
.entry .entry-header .entry-close:hover {
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.5));
}
.entry .entry-header .entry-close:hover line {
  stroke: ivory;
}
.entry hr:not(.entry-content hr) {
  margin: 1rem 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.25);
}
.entry .entry-content {
  overflow: auto;
}
.entry .entry-content h1, .entry .entry-content h2, .entry .entry-content h3, .entry .entry-content h4, .entry .entry-content h5, .entry .entry-content h6 {
  font-weight: normal;
}
.entry .entry-content h1 {
  font-size: 1.75rem;
  line-height: 2rem;
  margin-bottom: 0.75rem;
  margin-top: 1.75rem;
}
.entry .entry-content h2 {
  font-size: 1.5rem;
  line-height: 1.75rem;
  margin-bottom: 0.5rem;
  margin-top: 1.5rem;
}
.entry .entry-content h3 {
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin-bottom: 0.25rem;
  margin-top: 1.25rem;
}
.entry .entry-content p {
  margin-bottom: 1rem;
  word-wrap: break-word;
}
.entry .entry-content hr {
  margin: 0.5rem 1rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.entry .entry-content ul {
  list-style-type: disc;
  margin-bottom: 1rem;
}
.entry .entry-content ul li {
  margin-left: 1rem;
}
.entry .entry-content table {
  margin: 0 auto 1rem;
  border-collapse: collapse;
}
.entry .entry-content table th, .entry .entry-content table td {
  padding: 0.25rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.entry .entry-content img {
  max-width: 100%;
  margin: 0.5rem;
}
.entry .entry-content img:hover {
  cursor: zoom-in;
}
.entry .entry-content a {
  text-decoration: none;
  color: #652D00;
}
.entry .entry-content a:hover {
  text-decoration: underline;
}
.entry .entry-content .float-right {
  float: right;
}
.entry .entry-content .br4 img {
  border-radius: 16px;
}
.entry .entry-content div {
  margin: 0 2rem 1rem;
}


