@import "common.scss";

#left-overlay {
  padding-left: 1rem;
  box-shadow: .5rem 0 1rem rgba(0 0 0 / .05);
  max-height: 100vh;

  header {
    margin-right: 1rem;
    color: $tardis;

    height: 3rem;

    h1 {
      font-weight: lighter;
      font-size: 3rem;
      line-height: 3rem;
      letter-spacing: -.2rem;
    }
  }

  hr {
    margin: .5rem 1rem .5rem 0;
    border: 1px solid rgba(0 0 0 / .1);
  }

  nav {
    #categories {
      padding-right: 1rem;
      margin-bottom: .5rem;

      grid-template-columns: repeat(4, 1fr);
      justify-items: center;

      svg {
        width: 3rem;
        height: 3rem;

        filter: drop-shadow(0 0 4px rgba(255 255 240 / .5));

        path.stroke-fill { stroke: $tardis; }
        path:not(.stroke-fill) { fill: $tardis; }

        &, path {
          transition: all .25s ease;
        }

        &:hover, &.selected {
          cursor: pointer;
          filter: drop-shadow(0 0 4px rgba(0 0 0 / .5));

          path.stroke-fill {
            stroke: ivory;
          }

          path:not(.stroke-fill) {
            fill: ivory;
          }
        }
      }
    }

    #left-links-container {

      margin-top: 1rem;

      #left-links-sorter {
        width: 100%;

        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;

        h3 {
          font-weight: normal;
          font-size: 1.5rem;
          line-height: 2rem;
        }

        .wrapper {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: 1fr;

          padding-right: 1rem;

          button {
            height: 2rem;
            font-size: 1rem;
            border-radius: 0;
            border: none;
            background: none;

            transition: all .125s ease-in-out;

            &:hover, &.selected {
              cursor: pointer;
              color: ivory;
              text-shadow: 0 0 4px rgba(0 0 0 / .8);
              border-bottom: 2px solid ivory;
            }
          }
        }
      }

      ul {
        padding-left: 1rem;
        list-style-type: none;
        padding-top: .5rem;
        overflow-y: auto;
        height: calc(100vh - 10.5rem); // don't touch

        li {
          line-height: 1rem;

          a {
            margin-left: 1rem;
            font-size: 1rem;
            transition: all .125s ease;
            text-decoration: none;
            color: $brown;
            overflow-wrap: break-word;
            hyphens: auto;

            &:hover {
              cursor: pointer;
              color: ivory;
              text-shadow: 1px 1px 2px rgba(0 0 0 / .8);

              &.tools-link { cursor: alias; }
            }
          }

          margin-bottom: .5rem;
        }

        padding-bottom: 2rem;
      }
    }
  }
}