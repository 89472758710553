// widths
$line: 75ch;

// colors
$tardis: #003865;
$blue: #005498;
$brown: #652D00;
$violet: #650038;
$green: #386500;

$burgundy: #7c2529;
$gold: #f0b323;

// font-families
$base-font: system-ui, -apple-system, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";