#left-overlay {
  padding-left: 1rem;
  box-shadow: 0.5rem 0 1rem rgba(0, 0, 0, 0.05);
  max-height: 100vh;
}
#left-overlay header {
  margin-right: 1rem;
  color: #003865;
  height: 3rem;
}
#left-overlay header h1 {
  font-weight: lighter;
  font-size: 3rem;
  line-height: 3rem;
  letter-spacing: -0.2rem;
}
#left-overlay hr {
  margin: 0.5rem 1rem 0.5rem 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
#left-overlay nav #categories {
  padding-right: 1rem;
  margin-bottom: 0.5rem;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
}
#left-overlay nav #categories svg {
  width: 3rem;
  height: 3rem;
  filter: drop-shadow(0 0 4px rgba(255, 255, 240, 0.5));
}
#left-overlay nav #categories svg path.stroke-fill {
  stroke: #003865;
}
#left-overlay nav #categories svg path:not(.stroke-fill) {
  fill: #003865;
}
#left-overlay nav #categories svg, #left-overlay nav #categories svg path {
  transition: all 0.25s ease;
}
#left-overlay nav #categories svg:hover, #left-overlay nav #categories svg.selected {
  cursor: pointer;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.5));
}
#left-overlay nav #categories svg:hover path.stroke-fill, #left-overlay nav #categories svg.selected path.stroke-fill {
  stroke: ivory;
}
#left-overlay nav #categories svg:hover path:not(.stroke-fill), #left-overlay nav #categories svg.selected path:not(.stroke-fill) {
  fill: ivory;
}
#left-overlay nav #left-links-container {
  margin-top: 1rem;
}
#left-overlay nav #left-links-container #left-links-sorter {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
}
#left-overlay nav #left-links-container #left-links-sorter h3 {
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 2rem;
}
#left-overlay nav #left-links-container #left-links-sorter .wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  padding-right: 1rem;
}
#left-overlay nav #left-links-container #left-links-sorter .wrapper button {
  height: 2rem;
  font-size: 1rem;
  border-radius: 0;
  border: none;
  background: none;
  transition: all 0.125s ease-in-out;
}
#left-overlay nav #left-links-container #left-links-sorter .wrapper button:hover, #left-overlay nav #left-links-container #left-links-sorter .wrapper button.selected {
  cursor: pointer;
  color: ivory;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.8);
  border-bottom: 2px solid ivory;
}
#left-overlay nav #left-links-container ul {
  padding-left: 1rem;
  list-style-type: none;
  padding-top: 0.5rem;
  overflow-y: auto;
  height: calc(100vh - 10.5rem);
  padding-bottom: 2rem;
}
#left-overlay nav #left-links-container ul li {
  line-height: 1rem;
  margin-bottom: 0.5rem;
}
#left-overlay nav #left-links-container ul li a {
  margin-left: 1rem;
  font-size: 1rem;
  transition: all 0.125s ease;
  text-decoration: none;
  color: #652D00;
  overflow-wrap: break-word;
  hyphens: auto;
}
#left-overlay nav #left-links-container ul li a:hover {
  cursor: pointer;
  color: ivory;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
}
#left-overlay nav #left-links-container ul li a:hover.tools-link {
  cursor: alias;
}


